import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import NotFoundPage from './NotFoundPage'; // Ensure this path is correct
import HomePage from './HomePage'; // Import the HomePage component
import './App.css';

// Placeholder Game component for demonstration
const Game = () => (
  <div>
    <h1>Game Section</h1>
    <p>This is where the game will be.</p>
  </div>
);

function App() {
  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize('G-EHCE7K0BCB'); // Ensure this is your actual tracking ID
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <Router>
      <div>
        {/* Navigation links as an example */}
        <nav>
          <Link to="/">Home</Link> | <Link to="/game">Game</Link>
        </nav>

        {/* Route configuration */}
        <Switch>
          <Route path="/" exact component={HomePage} /> {/* Use HomePage instead of the placeholder Home */}
          <Route path="/game" component={Game} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
