// HomePage.js
import React from 'react';
import './HomePage.css'; // Import the CSS

function HomePage() {
  return (
    <div className="container">
  <div className="logo">
    <img src="logo.png" alt="Your Logo" />{" "}
  </div>
  <div className="video-row">
    <div className="video">
      <iframe
        width={640}
        height={360}
        src="https://www.youtube.com/embed/tD2AenQVPvE"
        title="YouTube video player"
        frameBorder={0}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen=""
      />
    </div>
  </div>
  <div className="links">
    <a
      href="https://www.twitch.tv/Kiroshimatv"
      target="_blank"
      className="px-6 py-2 border border-gray-700 hover:border-white transition-colors"
    >
      KiroshimaTV @ Twitch!
    </a>
    <a
      href="https://youtube.com/@kiroshima-official"
      target="_blank"
      className="px-6 py-2 border border-gray-700 hover:border-white transition-colors"
    >
      Kiroshima-Official @ YouTube!
    </a>
  </div>
</div>

  );
}

export default HomePage;
