import React from 'react';

function NotFoundPage() {
  return (
    <div>
      <p>
        <span style={{ fontSize: '22px' }}>lol... You are bad at video games.</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontSize: '22px' }}>
          <img alt="" src="https://media.tenor.com/I7iswCYiiXYAAAAd/gagging-gag.gif" style={{ float: 'left', height: '354px', width: '448px' }} />
        </span>
      </p>
    </div>
  );
}

export default NotFoundPage;
